<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/whatwedo/list/what-we-do'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @created-modal="addInAppMessage" @language-modal="showEditLanguage" :addButton="false" :dataPagination="true" :postGetData="true"/>

          <CreateModal
              :show="showCreateModal"
              modalTitle="Create In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'language', type: 'select', model: 'language', placeholder: 'Enter Language', options: supportedLanguages , id: 'id', value: 'name' },
                { label: 'title', type: 'text', model: 'title', required: false },
                { label: 'text', type: 'text', model: 'text', required: false },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />


          <EditModal
              :show="showEditModal"
              modalTitle="Edit In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'language', type: 'text', model: 'language', placeholder: 'Enter id', required: false },
                { label: 'title', type: 'text', model: 'title', placeholder: 'Enter type', required: false },
                { label: 'text General', type: 'text', model: 'text', required: true },
              ]"
              :detailEndpoint="`/admin/whatwedo/translation/${selectedObjId}/tr`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />

          <TranslationsModal
              :show="showEditLanguageModal"
              @hide="showEditLanguageModal = false"
              :contentId="editTranslationContentId"
              :type="'what-we-do'"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  components: {TranslationsModal, AstopiaTable},
  data(){
    return {
      columns: [
        {field: "type", header: "type", functionType: "none", edit: true, delete: true, selected: true},
        {field: "createdDate", header: "createdDate", functionType: "Date", selected: true},
        {field: "lastUpdateDate", header: "lastUpdateDate", functionType: "Date", selected: true},
        {field: "text", header: "text", functionType: "bool", selected: true},
        {field: "title", header: "title", functionType: "bool", selected: true},
        {field: "isGeneral", header: "isGeneral", functionType: "bool", selected: true},
        {field: "isPremium", header: "isPremium", functionType: "bool", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", created: false, edit: false, delete: false, translations: true, selected: true},
      ],
      data:{
        language: null,
        contentId: "53510",
        text: null,
        title: null,
      },
      dataUpdate:{
        "id": 0,
        "language": "string",
        "title": "string",
        "text": "string",
        "isVerified": true,
        "secondaryText": "string",
        "secondaryTitle": "string",
        "audioUrl": "string",
        "photoUrl": "string"
      },
      showModalCrud: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      dialogTypes: null,
      showEditLanguageModal: false,
      editTranslationContentId: 0,
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/in-app-message/dialog-types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    createObj(obj) {
      this.data.language = obj.language;
      this.data.text = obj.text;
      this.data.title = obj.title;
      this.$appAxios.post("/admin/whatwedo/translation", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    detailSee(obj){
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    detailSee2(obj){
      this.$router.push({name:"WhatWeDoTranslations", params:{id:obj.id}})
    },
    editObj(obj) {
      this.dataUpdate.id = obj.id;
      this.dataUpdate.type = obj.type;
      this.dataUpdate.gender = obj.gender;
      this.dataUpdate.isGeneral = obj.isGeneral;
      this.dataUpdate.isPremium = obj?.isPremium;
      this.dataUpdate.showCount = obj?.showCount;
      this.dataUpdate.score = obj?.score;
      const numericValue = Number(obj?.dialogType);
      if (numericValue){
        this.dataUpdate.dialogType = numericValue;
      }
      this.dataUpdate.isOnceShow = obj?.isOnceShow;
      this.dataUpdate.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.$appAxios.put("/admin/in-app-message", this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(() => {
        this.showEditModal = false
      })
    },
    showEditLanguage(obj) {
      this.editTranslationContentId = obj.id;
      this.showEditLanguageModal = true;
    },
    getLookups(){
      this.showModalCrud = true
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    }
  }
}
</script>

