<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/in-app-message/translations/'"
                        :default-post-data="this.$route.params.id + '?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'total_records'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addInAppMessage"  :addButton="true" :isOnlyNodata="false" :dataPagination="true" :postGetData="true" :goBackButton="true"/>



          <CreateModal
              :show="showCreateModal"
              modalTitle="Create translation"
              modalWidth="50%"
              :formFields="[
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter title', required: false },
                { label: 'Text', type: 'text', model: 'text', required: true },
                { label: 'Language', type: 'drop', model: 'language', placeholder: 'Enter language', options: supportedLanguages , id: 'id', value: 'name' },
                // { label: 'Language', type: 'text', model: 'language', placeholder: 'Enter language' },
                { label: 'Photo Url', type: 'text', model: 'photoUrl', required: true },
                { label: 'Deeplink', type: 'text', model: 'secondaryText', placeholder: 'Enter Secondary Text', required: true },
                { label: 'Is Verified', type: 'switch', model: 'isVerified', placeholder: 'Enter Is Verified', required: true }
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable2"
          />

          <EditModal
              :show="showEditModal"
              modalTitle="Edit translation"
              modalWidth="50%"
              :formFields="[
                { label: 'Id', type: 'number', model: 'id', placeholder: 'Enter title', required: false },
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter title', required: false },
                { label: 'Text', type: 'text', model: 'text', required: true },
                // { label: 'Language', type: 'text', model: 'language', placeholder: 'Enter language' },
                { label: 'Photo Url', type: 'text', model: 'photoUrl', required: true },
                { label: 'Deeplink', type: 'text', model: 'secondaryText', placeholder: 'Enter Deeplink', required: true },
                { label: 'Is Verified', type: 'switch', model: 'isVerified', placeholder: 'Enter Is Verified', required: true }
              ]"
              :detailEndpoint="`/admin/in-app-message/translation/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />


          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete In App Message"
              deleteMessage="Are you sure you want to delete this in app message?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/in-app-message/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";

export default {
  components: {AstopiaTable},
  data(){
    return {
      columns: [
        {field: "title", header: "Title", functionType: "none", selected: true},
        {field: "text", header: "Text", functionType: "none", selected: true},
        {field: "photoUrl", header: "Photo Url", functionType: "none", selected: true},
        {field: "secondaryText", header: "Deeplink", functionType: "none", selected: true},
        {field: "isVerified", header: "Is Verified", functionType: "bool", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: false, selected: true},
      ],
      data:{
        "id": 0,
        "language": "string",
        "title": "string",
        "text": "string",
        "contentId": 0,
        "isVerified": true,
        "secondaryText": "string",
        "photoUrl": "string"
      },
      dataUpdate:{
        "id": 0,
        "language": "string",
        "title": "string",
        "text": "string",
        "contentId": 0,
        "isVerified": true,
        "secondaryText": "string",
        "photoUrl": "string"
      },
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    createObj(obj) {
      this.data.language = obj.language;
      this.data.title = obj.title;
      this.data.text = obj.text;
      this.data.contentId = parseInt(this.$route.params.id);
      this.data.isVerified = obj?.isVerified;
      this.data.secondaryText = obj?.secondaryText;
      this.data.photoUrl = obj?.photoUrl;
      this.$appAxios.post("/admin/in-app-message/create-translation", this.data).then(() => {
        this.refreshTable2()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    detailSee(obj) {
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate.id = obj?.id;
      // this.dataUpdate.language = obj?.language;
      this.dataUpdate.title = obj?.title;
      this.dataUpdate.text = obj?.text;
      this.dataUpdate.contentId = parseInt(this.$route.params.id);
      this.dataUpdate.isVerified = obj?.isVerified;
      this.dataUpdate.secondaryText = obj?.secondaryText;
      this.dataUpdate.photoUrl = obj?.photoUrl;
      this.$appAxios.put("/admin/in-app-message/translations", this.dataUpdate).then(() => {
        this.refreshTable2()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },

    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    },
    refreshTable2() {
      this.$refs.astopiaTable.fetchData();
    }
  }
}
</script>

