import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
import {appAxios} from '../utils/appAxios';
import router from "../router";

let ls = new SecureLS({isCompression:false})
export default createStore({
    state: {
        lang: null,
        token: null,
        country: localStorage.getItem("country"),
        toggeled: false,
        userInfo: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : {
                isChangeMaker: false,
                name: "",
                surname: "",
                registerStatus: 0,
            },
        occupations: [],
        relationships: [],
        houses: [],
        signs: [],
        genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
        supportedLanguages: [],
    },
    mutations: {
        logout(state) {
            state.token = null
            state.userInfo = null
            router.push("/welcome")
        },
        setToken(state, token) {
            state.token = token
        },
        setLang(state, language) {
            if (state.lang !== language) {
                state.lang = language;
            }
        },
        setToggled(state) {
            state.toggeled = !state.toggeled;
        },
        userInfo(state, data) {
            state.userInfo = data;
        },
        setOccupations(state, data) {
            state.occupations = data;
        },
        setRelationships(state, data) {
            state.relationships = data;
        },
        setHouses(state, data) {
            state.houses = data;
        },
        setSigns(state, data) {
            state.signs = data;
        },
        setCategories(state, data) {
            state.categories = data;
        },
        setSupportedLanguages(state, data) {
            state.supportedLanguages = data;
        },
    },
    getters: {
        occupations(state) {
            return state.occupations;
        },
        occupationIds(state) {
            return state.occupations.map((item) => item.id);
        },
        relationships(state) {
            return state.relationships;
        },
        relationshipIds(state) {
            return state.relationships.map((item) => item.id);
        },
        houses(state) {
            return state.houses;
        },
        signs(state) {
            return state.signs;
        },
        categories(state) {
            return state.categories;
        },
        supportedLanguages(state) {
            return state.supportedLanguages;
        }
    },
    actions: {
        async getOccupations({commit}) {
            try {
                const {data} = await appAxios.get("admin/global/occupations");
                commit("setOccupations", data);
            } catch (error) {
                console.error("Failed to fetch occupations:", error);
            }
        },
        async getRelationships({commit}) {
            const {data} = await appAxios.get("admin/global/relationships");
            commit("setRelationships", data);
        },
        async getHouses({commit}) {
            const {data} = await appAxios.get("admin/global/houses");
            commit("setHouses", data);
        },
        async getSigns({commit}) {
            const {data} = await appAxios.get("admin/global/signs");
            commit("setSigns", data);
        },
        async getCategories({commit}) {
            const {data} = await appAxios.get("admin/global/categories");
            commit("setCategories", data);
        },
        async getSupportedLanguages({commit}) {
            const {data} = await appAxios.get("admin/global/supported-languages");
            commit("setSupportedLanguages", data);
        },
    },
    modules: {},
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        })
    ]
});