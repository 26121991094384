<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/whatwedo/translation/'" :default-post-data="this.$route.params.id + '/tr?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @detail-see2="detailSee2" @add-button="addInAppMessage" :addButton="true" :isOnlyNodata="true"/>

          <CreateModal
              :show="showCreateModal"
              modalTitle="Create In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'Type', type: 'text', model: 'type', placeholder: 'Enter type', required: false },
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], id: 'id', value: 'value', required: true},
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: false },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: false },
                { label: 'Show Count', type: 'switch', model: 'showCount', required: false },
                { label: 'Limit', type: 'number', model: 'score', placeholder: 'Enter Limit', required: true },
                { label: 'Dialog Type', type: 'select', model: 'dialogType', options: dialogTypes, id: 'id', value: 'name', required: true},
                { label: 'Is Once Show', type: 'switch', model: 'isOnceShow', required: true },
                { label: 'In App Message Type Id', type: 'number', model: 'inAppMessageTypeId', placeholder: 'Enter In App Message Type Id', required: true }
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />

          <EditModal
              :show="showEditModal"
              modalTitle="Edit In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'id', type: 'number', model: 'id', placeholder: 'Enter id', required: false },
                { label: 'Type', type: 'text', model: 'type', placeholder: 'Enter type', required: false },
                { label: 'Gender', type: 'select', model: 'gender', options: [
                    { text: 'All', value: 0 },
                    { text: 'Man', value: 1 },
                    { text: 'Woman', value: 2 }], value: 'value', text: 'text'
                },
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: true },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: true },
                { label: 'Show Count', type: 'switch', model: 'showCount', required: true },
                { label: 'Limit', type: 'number', model: 'score', placeholder: 'Enter Limit', required: true },
                { label: 'Dialog Type', type: 'select', model: 'dialogType', options: dialogTypes , value: 'id', text: 'name'
                },
                { label: 'Is Once Show', type: 'switch', model: 'isOnceShow', required: true },
                { label: 'inAppMessageTypeId', type: 'number', model: 'inAppMessageTypeId', placeholder: 'Enter In App Message Type Id', required: true }
              ]"
              :detailEndpoint="`/admin/in-app-message/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />


          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete In App Message"
              deleteMessage="Are you sure you want to delete this in app message?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/in-app-message/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";

export default {
  components: {AstopiaTable},
  data(){
    return {
      columns: [
        {field: "language", header: "language", functionType: "none", selected: true},
        {field: "title", header: "title", functionType: "none", selected: true},
        {field: "text", header: "text", functionType: "none", selected: true},
        {field: "isVerified", header: "isVerified", functionType: "none", selected: true},
        {field: "secondaryText", header: "secondaryText", functionType: "none", selected: true},
        {field: "secondaryTitle", header: "secondaryTitle", functionType: "none", selected: true},
        {field: "audioUrl", header: "audioUrl", functionType: "none", selected: true},
        {field: "photoUrl", header: "photoUrl", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: false, selected: true},
      ],
      optionsList: [{id: "false", name: "App"},{id: "true", name: "Web"}],
      data:{
        "id": 0,
        "type": "test-what-we-do-23",
        "houseId": 10,
        "ascendantSignId": 2,
        "score": 10,
        "minAge": 10,
        "maxAge": 100,
        "gender": 2,
        "relationshipStatus": 2,
        "relationshipStatusIds": [
          3
        ],
        "occupationIds": [
          3
        ],
        "startDate": "2025-01-20T13:46:42.113Z",
        "endDate": "2025-01-20T13:46:42.113Z",
        "title": "test-what-we-do-23",
        "text": "test-what-we-do-23",
        "photoUrl": "test",
        "isGeneral": true,
        "showCount": true,
        "category": "string",
        "translationId": 0,
        "isVerified": true,
        "language": "string",
        "secondaryText": "string",
        "secondaryTitle": "string",
        "isPremium": true,
        "categoryIds": [
          0
        ],
        "autoTranslate": true,
        "autoAudioLanguages": [
          "en"
        ]
      },
      dataUpdate:{
        "id": 0,
        "type": "string",
        "houseId": 0,
        "ascendantSignId": 0,
        "score": 0,
        "minAge": 0,
        "maxAge": 0,
        "gender": 0,
        "relationshipStatus": 0,
        "relationshipStatusIds": [
          0
        ],
        "occupationIds": [
          0
        ],
        "startDate": "2025-01-20T15:05:09.921Z",
        "endDate": "2025-01-20T15:05:09.921Z",
        "title": "string",
        "text": "string",
        "photoUrl": "string",
        "isGeneral": true,
        "showCount": true,
        "category": "string",
        "translationId": 0,
        "isVerified": true,
        "language": "string",
        "secondaryText": "string",
        "secondaryTitle": "string",
        "isPremium": true,
        "categoryIds": [
          0
        ],
        "autoTranslate": true,
        "autoAudioLanguages": [
          "string"
        ]
      },
      showModalCrud: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      dialogTypes: null
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/in-app-message/dialog-types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    createObj(obj) {
      this.data.type = obj.type;
      this.data.gender = obj.gender;
      this.data.isGeneral = obj.isGeneral;
      this.data.isPremium = obj?.isPremium;
      this.data.showCount = obj?.showCount;
      this.data.score = obj?.score;
      this.data.dialogType = obj?.dialogType;
      this.data.isOnceShow = obj?.isOnceShow;
      this.data.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.$appAxios.post("/admin/whatwedo/what-we-do", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    detailSee(obj){
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    detailSee2(obj){
      this.$router.push({name:"Translations", params:{id:obj.id}})
    },
    editObj(obj) {
      this.dataUpdate.id = obj.id;
      this.dataUpdate.type = obj.type;
      this.dataUpdate.gender = obj.gender;
      this.dataUpdate.isGeneral = obj.isGeneral;
      this.dataUpdate.isPremium = obj?.isPremium;
      this.dataUpdate.showCount = obj?.showCount;
      this.dataUpdate.score = obj?.score;
      const numericValue = Number(obj?.dialogType);
      if (numericValue){
        this.dataUpdate.dialogType = numericValue;
      }
      this.dataUpdate.isOnceShow = obj?.isOnceShow;
      this.dataUpdate.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.$appAxios.put("/admin/in-app-message", this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    getLookups(){
      this.showModalCrud = true
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    }
  }
}
</script>

