<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/chakra/get-lookups'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'total_records'"
                        :dataPagination="true" :isOnlydata="true"
                        :getLookupsFilter="true"/>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";

export default {
  components: { AstopiaTable},
  data() {
    return {
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "ascendantSign", header: "Ascendant Sign", functionType: "none", selected: true},
        {field: "ascendantSignId", header: "Ascendant Sign Id", functionType: "none", selected: true},
        {field: "startDate", header: "Start Date", functionType: "Date", selected: true},
        {field: "firstWeekChakraId", header: "First Week Chakra Id", functionType: "none", selected: true},
        {field: "firstWeekChakra",header:"First Week Chakra", functionType: "none", edit: true, delete: false, selected: true},
        {field: "secondWeekChakraId", header: "Second Week Chakra Id", functionType: "none", selected: true},
        {field: "secondWeekChakra", header: "Second Week Chakra", functionType: "none", selected: true},
        {field: "thirdWeekChakraId", header: "Third Week Chakra Id", functionType: "none", selected: true},
        {field: "thirdWeekChakra", header: "Third Week Chakra", functionType: "none", selected: true},
        {field: "fourthWeekChakraId",header:"Fourth Week Chakra Id", functionType: "none", edit: true, delete: false, selected: true},
      ],
      showNewContentModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteObj: null,
      language: null,
      selectedObjId: null,
      data: {
        "name": "",
        "themes": "",
        "isActive": "",
        "chakraContents": [
          {
            "id": null,
            "type": "",
            "variant": 0,
            "text": "",
            "language": "",
            "imagePath": ""
          }
        ]
      },
      dataUpdate: {
        "name": "",
        "themes": "",
        "isActive": "",
        "chakraContents": [
          {
            "type": "",
            "variant": 0,
            "text": "",
            "language": "",
            "imagePath": ""
          }
        ]
      }
    };
  },
  methods: {
    refresh() {
      this.$router.go(0);
    }
  }
}
</script>