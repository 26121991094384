<template>
  <div
    class="list-group menu"
    role="tablist"
    v-if="this.$store.state.token"
  >
    <a class="list-group-item list-group-item-action d-flex flex-column menu-first"
      role="tab">
      <LocalSwitcher />
    </a>
    <router-link to="/" class="list-group-item list-group-item-action" role="tab">{{ $t("menu.home") }}</router-link>
    <router-link to="/dailyContent" class="list-group-item list-group-item-action" role="tab">{{ $t("menu.content-look-up") }}</router-link>
    <router-link to="/astrologs" class="list-group-item list-group-item-action" role="tab">{{ $t("menu.astrologs") }}</router-link>

    <!-- logout -->
    <a
      class="list-group-item list-group-item-action pb-4 logout"
      role="tab"
      @click="logout"
    >
      {{ $t("menu.exit") }}</a
    >
  </div>
</template>

<script>
import LocalSwitcher from "@/components/LocalSwitcher.vue";

export default {
  name: "Menu",
  components: {
    LocalSwitcher
  },
  methods: {
    mobileGoPage(page) {
      this.toggle();
      this.$router.push(page)
    },
    toggle() {
      this.$store.commit("setToggled");
    },
    logout() {
      this.$swal
        .fire({
          text: this.$t("menu.exitConfirm"),
          showCancelButton: true,
          customClass: {
            container: "my-sweet",
          },
          confirmButtonText: this.$t("global.yes"),
          cancelButtonText: this.$t("global.no"),
          icon: "warning",
        })
        .then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            window.location = "/";
          }
        });
    },
  },
};
</script>

<style scoped>
.logout {
  cursor: pointer !important;
  color: #fd6a57 !important;
  margin-bottom: 100px;
}

.menu {
  transition: 3s;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  left: -100%;
  top: 0;
  z-index: 4;
  flex-direction: column;
  background-color: #ffffff;
  text-align: center;
  transition: 0.6s;
  overflow-y: scroll;
  overflow-x: hidden;
}

.menu-first {
  padding-top: 30px;
}

.list-group {
  width: 40% !important;
}

.icon-box {
  height: 60px;
  width: 60px;
  background: #eff0f1;
  border-radius: 6px;
}

.menu .list-group-item {
  font-size: 15px;
  font-family: "Ubuntu";
  line-height: 24px;
  color: #6367b7;
  background-color: #ffffff;
  border-color: transparent;
  display: flex;
  align-items: center;
  line-height: 35px;
  cursor: pointer;
  text-align: start;
}

@media (min-width: 576px) {
  .menu {
    width: 30% !important;
  }
}

@media (min-width: 672px) {
  .menu {
    width: 20% !important;
    transition: 3s;
  }
}

a {
  color: #475569 !important;
}
</style>
