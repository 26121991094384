<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <Dialog header="Upload" v-model:visible="showUploadSection" modal>
            <FileUpload
                class="ms-2"
                name="file[]"
                accept=".xlsx"
                :file-limit="1"
                :customUpload="true"
                @uploader="uploader"
                :maxFileSize="5000000"
                choose-label="Choose File"
                upload-label="Upload"
                cancel-label="Cancel"
            />
          </Dialog>

          <DataTable
              :value="contents"
              :lazy="true"
              :paginator="true"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 20, 50, 100]"
              dataKey="id"
              :rows="10"
              ref="dt"
              :totalRecords="contentsTotalRecords"
              :loading="loading"
              @page="onContentsPage($event)"
              @sort="onContentsSort($event)"
              @filter="onContentsFilter($event)"
              responsiveLayout="scroll"
              class="p-datatable-striped"
              v-model:filters="filters"
              filterDisplay="menu"
              removableSort
              :globalFilterFields="['name', 'isActive']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                  <ToggleButton
                      v-model="showUploadSection"
                      onLabel="Upload"
                      offLabel="Upload"
                      class="ms-2"
                  />
                </div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText
                        v-model="filters['global'].value"
                        placeholder="Keyword Search"
                        @change="getContents"
                    />
                  </span>
              </div>
            </template>
            <template #empty> No contents found.</template>
            <template #loading> Loading contents data. Please wait.</template>
            <Column field="photoUrl" header="Photo" ref="photoUrl">
              <template #body="slotProps">
                <img :src="slotProps.data.photoUrl" style="width:40px"/>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="title" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="date" header="Date" ref="date">
              <template #body="slotProps">
                <span class="m-0">{{
                    formatDate(slotProps.data.startDate) + "-" + formatDate(slotProps.data.endDate)
                  }}</span>
              </template>
            </Column>
            <Column field="ascendant" header="Ascendant" ref="ascendant">
              <template #body="slotProps">
                <span class="m-0">{{ this.signs.find(x => x.id == slotProps.data.ascendantSignId)?.name }}</span>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
            </Column>
            <Column field="relationshipStatusIds" ref="relationshipStatusIds" header="RelationshipStatus">
              <template #body="slotProps">
                <div
                    v-if="this.relationshipIds.every(x => slotProps.data.relationshipStatusIds.includes(x)) || slotProps.data.relationshipStatusIds.length === 0">
                  <span class="badge bg-primary rounded-pill">All</span>
                </div>
                <div v-else>
                  <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                    <div v-for="relationshipId in slotProps.data.relationshipStatusIds" :key="relationshipId">
                      <span class="mt-1">{{ this.relationships?.find(x => x.id == relationshipId)?.name }}</span>
                    </div>
                  </ScrollPanel>
                </div>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['relationshipStatusIds'].value" :options=relationships optionLabel="name"
                             optionValue="id" class="p-column-filter" :maxSelectedLabels="3"/>
              </template>
            </Column>
            <Column field="occupation" header="Occupations" ref="slug">
              <template #body="slotProps">
                <div
                    v-if="this.occupationIds.every(x => slotProps.data.occupationIds.includes(x)) || slotProps.data.occupationIds.length === 0">
                  <span class="badge bg-primary rounded-pill">All</span>
                </div>
                <div v-else>
                  <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                    <div v-for="occupationId in slotProps.data.occupationIds" :key="occupationId">
                      <span class="mt-1">{{ this.occupations?.find(x => x.id == occupationId)?.name }}</span>
                    </div>
                  </ScrollPanel>
                </div>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['occupation'].value" :options=occupations optionLabel="name"
                             optionValue="id" class="p-column-filter" :maxSelectedLabels="3"/>
              </template>
            </Column>
            <Column field="minAge" header="Min Age" ref="minAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.minAge }}</span>
              </template>
            </Column>
            <Column field="maxAge" header="Max Age" ref="maxAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.maxAge }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    icon="pi pi-book"
                    class="p-button p-button-sm me-2 my-2"
                    @click="showEditLanguage(slotProps.data.id)"
                />
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Content" v-model:visible="showNewContentModal" modal :style="{'width':'40%'}">
        <Form>
          <div class="row mb-3 mt-3">
            <label class="d-block">Date*:</label>
            <Calendar v-model="newContent.date" :disabled-days="[0,2,3,4,5,6]" selection-mode="range"
                      dateFormat="dd.mm.yy" @date-select="onDateSelect($event)"/>
            <div class="col-md-12 form-group mt-2">
              <label class="d-block">Auto Translate:</label>
              <InputSwitch v-model="newContent.autoTranslate"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Image:</label>
              <Dropdown :options="images" v-model="newContent.photoUrl" optionLabel="id" optionValue="id">
                <template #value="slotProps">
                  <div class="p-dropdown-content-image" v-if="slotProps.id">
                    <img :src="slotPorps.id"/>
                  </div>
                  <span v-else>
                    Select
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="p-dropdown-content-image">
                    <img class="img-fluid" :src="slotProps.option.id"/>
                  </div>
                </template>
              </Dropdown>
              <img class="img-fluid mx-2 p-dropdown-content-image-preview" :src="newContent.photoUrl"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Title*:</label>
              <InputText class="w-100" type="text" v-model="newContent.title"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Text*:</label>
              <Textarea class="w-100" type="text" rows="8" v-model="newContent.text"/>
            </div>

            <div class="form-group" :class="{'col-md-6':!newContent.isGeneral, 'col-md-12':newContent.isGeneral}">
              <label class="d-block">General:</label>
              <InputSwitch v-model="newContent.isGeneral"/>
            </div>

            <div v-if="!newContent.isGeneral" class="col-md-6 form-group">
              <label class="d-block">Ascendant Sign:</label>
              <Dropdown v-model="newContent.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                        placeholder="Select Sign"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Min Age:</label>
              <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" mode="decimal" showButtons v-model="newContent.minAge"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Max Age:</label>
              <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" mode="decimal" showButtons v-model="newContent.maxAge"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Man'}, {'id': 2 , 'value':'Woman'}]"
                        v-model="newContent.gender" optionLabel="value" optionValue="id"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Relationship:</label>
              <MultiSelect
                  :options="relationships"
                  v-model="newContent.relationshipStatusIds"
                  optionLabel="name"
                  optionValue="id"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Occupations:</label>
              <MultiSelect
                  v-model="newContent.occupationIds"
                  :options="occupations"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Occupations"
              />
            </div>


          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showNewDailyContentModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent('weekly-suggestion-card')"/>
          </div>
        </Form>
      </Dialog>

      <TranslationsModal
          :show="showEditLanguageModal"
          @hide="showEditLanguageModal = false"
          :contentId="editTranslationContentId"
      />

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{'width':'40%'}">
        <div class="row mb-3 mt-3">
          <label class="d-block">Date:</label>
          <Calendar v-model="editContentModel.date" :disabled-days="[0,2,3,4,5,6]" selection-mode="range"
                    dateFormat="dd.mm.yy" @date-select="onDateSelectEdit($event)"/>
          <div class="col-md-12 form-group">
            <label class="d-block">Image:</label>
            <Dropdown :options="images" v-model="editContentModel.photoUrl" optionLabel="id" optionValue="id">
              <template #value="slotProps">
                <div class="p-dropdown-content-image" v-if="slotProps.id">
                  <img :src="slotPorps.id"/>
                </div>
                <span v-else>
                  Select
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-content-image">
                  <img class="img-fluid" :src="slotProps.option.id"/>
                </div>
              </template>
            </Dropdown>
            <img class="img-fluid mx-2 p-dropdown-content-image-preview" :src="editContentModel.photoUrl"/>
          </div>
          <div class="form-group"
               :class="{'col-md-6':!editContentModel.isGeneral, 'col-md-12':editContentModel.isGeneral}">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-6 form-group">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Man'}, {'id': 2 , 'value':'Woman'}]"
                      v-model="editContentModel.gender" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>
            <MultiSelect
                :options="relationships"
                v-model="editContentModel.relationshipStatusIds"
                optionLabel="name"
                optionValue="id"
            />
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect
                v-model="editContentModel.occupationIds"
                :options="occupations"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Occupations"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  components: {
    Header,
    DataTable,
    Column,
    TranslationsModal,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      schema,
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      images: [],
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        houseId: null,
        ascendantSignId: null,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatus: 0,
        photoUrl: "",
        occupationIds: [],
        relationshipStatusIds: [],
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      showUploadSection: false,
      editTranslationContentId: 0,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    for (var i = 154; i < 260; i++) {
      this.images.push({
        id: "https://cdn.astopia.com/icons/" + i + ".jpg",
        name: ""
      })
    }

    this.getContents();
    this.newContent.occupationIds = this.occupations.map(occupation => occupation.id);
    this.newContent.relationshipStatusIds = this.relationships.map(relationship => relationship.id);
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/content/list/weekly-suggestion-card", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.showNewContentModal = true;
    },
    showEditLanguage(id) {
      this.editTranslationContentId = id;
      this.showEditLanguageModal = true;
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.date && this.newContent.text && (this.newContent.ascendantSignId != null || this.newContent.isGeneral))) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      var startDate = this.newContent.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.newContent.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      this.$appAxios.post("admin/content/v2/weekly-suggestion-card", {
        type: "weekly-suggestion-card",
        houseId: this.newContent.houseId,
        ascendantSignId: this.newContent.ascendantSignId,
        score: this.newContent.score,
        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatusIds: this.newContent.relationshipStatusIds,
        occupationIds: this.newContent.occupationIds,
        startDate: startDateUtc,
        endDate: endDateUtc,
        title: this.newContent.title,
        text: this.newContent.text,
        isGeneral: this.newContent.isGeneral,
        photoUrl: this.newContent.photoUrl,
        autoTranslate: this.newContent.autoTranslate
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.showNewContentModal = false;

        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
      });
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.editContentModel.date = [dayjs(resp.data.startDate).toDate(), dayjs(resp.data.endDate).toDate()];
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      var startDate = this.editContentModel.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.editContentModel.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      this.$appAxios.put( "admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            houseId: this.editContentModel.houseId,
            gender: this.editContentModel.gender,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            score: this.editContentModel.score,
            relationshipStatus: this.editContentModel.relationshipStatus,
            occupationIds: this.editContentModel.occupationIds,
            startDate: startDateUtc,
            endDate: endDateUtc,
            type: "weekly-suggestion-card",
            isGeneral: this.editContentModel.isGeneral,
            photoUrl: this.editContentModel.photoUrl
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    onDateSelect(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.newContent.date[1] = date;
    },
    onDateSelectEdit(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.editContentModel.date[1] = date;
    },
    uploader(event) {
      var file = event.files[0];

      let formData = new FormData();
      formData.append("file", file);

      this.$appAxios.post("admin/content/suggestion-card/upload", formData, {
            headers: {
              'Content-Type': file.type,
            }
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'File was uploaded successfully',
              life: 3000
            });
            this.getContents();
          }).catch(error => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data.error, life: 3000});
      });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}

.p-dropdown-content-image {
  width: 75px;
  height: 75px;
  padding: 10px;
}

.p-dropdown-content-image-preview {
  width: 120px;
  height: 120px;
}
</style>
  