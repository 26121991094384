import axios from "axios";
import Swal from "sweetalert2";
import ToastService from 'primevue/toastservice';
import store from "../store";
export const appAxios = axios.create({
    baseURL:process.env.VUE_APP_API_BASE_URL,
})
appAxios.interceptors.request.use(config => {

    config.headers["Accept"] = "application/json";
    config.headers["Authorization"] = "Bearer " + store.state.token;
    config.headers["Locale"] = new Date().getTimezoneOffset()
    config.headers["Accept-Country"] = store.state.country;

    return config;
});

appAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error)

        let errMessage = "";
        if (error?.response?.data?.errors){
            Object.entries(error.response.data.errors).forEach(item => {
                errMessage += item[1] + "<br>"
            })

            Swal.fire({
                title: "",
                html: errMessage, // HTML formatında gösteriyoruz
                icon: "error",
                showCloseButton: true,
            });
        } else if (error.response.data?.error) {
            // Tek bir hata mesajı varsa göster
            Swal.fire("", error.response.data?.error, "error");
        }
        if ((error?.response?.status == 401) || ( errMessage === "Unauthorized.<br>" && store.state.token)) {
            store.commit("logout")
        }

        if (error.response.status === 403) {
            ToastService.add({severity:'error', summary: 'Error', detail:'You are not authorized to take this action.', life: 3000});
        }

        return Promise.reject(error);
    }
)