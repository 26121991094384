<template>
  <Header headerClass="transparent"/>
  <main>
    <div class="wrapper fadeInDown">
      <div id="formContent">

        <!-- Icon -->
        <div class="fadeIn first mt-4">
          <LogoSVG/>
        </div>
        <!-- Login Form -->
        <div class="row d-flex justify-content-center mt-3">
          <input type="text" name="email" v-model="email" id="login" class="fadeIn second"
                 :placeholder="$t('placeholders.email')">
          <input type="password" name="password" v-model="password" id="password" class="fadeIn third"
                 :placeholder="$t('placeholders.password')">
        </div>
        <div class="row d-flex justify-content-center mx-4">
          <input @click="login" type="submit" class="fadeIn fourth mt-3 w-100" :value="$t('global.login')">
        </div>

      </div>
    </div>
    <!-- <div>
      <div class="col-xl-4 offset-xl-4 col-md-6 offset-md-3" style="padding-top:100px;">
        <h1 class="main-header">{{ $t("welcome.header") }}</h1>
        <div class="p-0 m-0">
          <Form
            @submit="login"
            :validation-schema="schemaEmail"
            class="p-2 px-0 d-flex flex-column col-xl-8 col-xxl-6 mx-2"
          >
            <div class="row mt-4">
              <div class="col-12">
                <HUB21Input
                  type="text"
                  name="email"
                  v-model="email"
                  :value="email"
                  placeholder="placeholders.email"
                  inputClass="form-control form-control-solid h-auto mb-1 mt-2"
                />
              </div>
              <div class="col-12">
                <HUB21Input
                  type="password"
                  name="password"
                  v-model="password"
                  :value="password"
                  placeholder="placeholders.password"
                  inputClass="form-control form-control-solid h-auto mb-1 mt-2"
                />
              </div>
              <div class="col-12">
                <HUB21Button
                  type="submit"
                  btnclass="btn btn-purple w-md-50 pc-2 psc-4 pec-4 mt-3 text-nowrap"
                  :text="sendButtonText"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div> -->
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import LogoSVG from "@/components/LogoSVG.vue"
// import { Form } from "vee-validate";
import {string} from "yup";

export default {
  name: "App",
  components: {
    Header,
    LogoSVG,
    // Form
  },
  data() {
    const schemaEmail = {
      email: string().required(this.$t("errors.email-req")).email(this.$t("errors.email-req")),
      password: string().required(this.$t("errors.password-req")),
    };

    return {
      schemaEmail,
      email: "",
      password: ""
    };
  },

  mounted() {
  },
  methods: {
    login() {
      this.$appAxios
          .post("admin/account/login", {
            email: this.email,
            password: this.password,
            token: "",
          })
          .then(resp => {
            this.$store.commit("setToken",resp.data.token)
            this.$router.push("/")
          })
          .catch((err) => {
            this.showGlobalErrorMessage(err.response.data.error);
          });
    },

  },
};
</script>

<style scoped>
main {
  height: 100vh;
}

/* BASIC */

body {
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}


/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 20px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input:placeholder {
  color: #cccccc;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}


/* OTHERS */
/* 
*:focus {
    outline: none;
}  */

#icon {
  width: 60%;
}

/*
* {
  box-sizing: border-box;
} */
</style>
