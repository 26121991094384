<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['global', 'name', 'isActive', 'relationshipStatusIds']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContentModal = true"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                  <Dropdown v-model="selectedContentType" :options="contentTypes" optionLabel="name" optionValue="id"
                            @change="getContents" class="ms-2" placeholder="Select Content Type"/>
                </div>
                <div>
                  <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined d-inline me-2"
                          @click="clearFilter()"/>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                  </span>
                </div>
              </div>
            </template>

            <template #empty> No daily contents found.</template>
            <template #loading> Loading daily contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data.text }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="photoUrl" header="Image">
              <template #body="slotProps">
                <img :src="slotProps.data.photoUrl" alt="content"/>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-tooltip.left="'Edit Translations'"
                    icon="pi pi-book"
                    class="p-button p-button-sm me-2 my-2"
                    @click="showEditLanguage(slotProps.data.id)"
                />
                <Button v-tooltip.left="'Edit Content'" icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button v-tooltip.left="'Delete Content'" icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="Add Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">

        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Auto Translate*:</label>
            <InputSwitch v-model="newContent.autoTranslate" @change="updateAutoAudio"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Auto Audio Languages*:</label>
            <MultiSelect
                class="w-100"
                v-model="newContent.autoAudioLanguages"
                :options="supportedLanguages"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Languages"
            />
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Title*:</label>
            <InputText class="w-100" type="text" v-model="newContent.title"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Text*:</label>
            <Textarea class="w-100" type="text" rows="5" v-model="newContent.text"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Story Prefix:</label>
            <InputText class="w-100" type="text" v-model="newContent.storyPrefix"/>
          </div>

          <div class="col-md-6 form">
            <label class="d-block">Premium:</label>
            <InputSwitch v-model="newContent.isPremium"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">General:</label>
            <InputSwitch v-model="newContent.isGeneral"/>
          </div>

          <div class="col-md-6 form-group" v-show="!newContent.isGeneral">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="newContent.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Show After Date*:</label>
            <Calendar class="w-100" type="text" v-model="newContent.startDate"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Event Date*:</label>
            <Calendar class="w-100" type="text" v-model="newContent.endDate"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Photo Url*:</label>
            <InputText class="w-100" type="text" v-model="newContent.photoUrl"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect class="w-100" v-model="newContent.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id" placeholder="Select Tags"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="newContent.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="newContent.maxAge"/>
          </div>


          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown class="w-100"
                      :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }]"
                      v-model="newContent.gender" optionLabel="value" optionValue="id"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect class="w-100" v-model="newContent.occupationIds" :options="occupations" optionLabel="name"
                         optionValue="id" placeholder="Select Occupations"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>
            <MultiSelect class="w-100" :options="relationships" v-model="newContent.relationshipStatusIds"
                         optionLabel="name" optionValue="id"/>
          </div>
        </div>









        <footer class="d-flex justify-content-end">
          <Button label="Cancel" class="p-button-secondary me-2" @click="showNewContentModal = false"/>
          <Button label="Save" class="p-button-success" @click="addContent"/>
        </footer>
      </Dialog>


      <TranslationsModal
          :show="showEditLanguageModal"
          @hide="showEditLanguageModal = false"
          :contentId="editTranslationContentId"
      />

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="d-block">Premium:</label>
            <InputSwitch v-model="editContentModel.isPremium"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>

          <div class="col-md-6 form-group" v-show="!editContentModel.isGeneral">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown
                :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }]"
                v-model="editContentModel.gender" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>
            <MultiSelect :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Single' },
            { 'id': 2, 'value': 'Married' }, { 'id': 3, 'value': 'Divorced' },
            { 'id': 4, 'value': 'Engaged' }, { 'id': 5, 'value': 'In Relationship' },
            { 'id': 6, 'value': 'Complicated' },
            { 'id': 7, 'value': 'Widowed' },
            { 'id': 8, 'value': 'Open Relationship' }]"
                         v-model="editContentModel.relationshipStatusIds" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect v-model="editContentModel.occupationIds" :options="occupations" optionLabel="name"
                         optionValue="id" placeholder="Select Occupations"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect v-model="editContentModel.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id"
                         placeholder="Select House"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Show After Date:</label>
            <Datepicker class="w-100" type="text" v-model="editContentModel.startDate"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Event Date:</label>
            <Datepicker class="w-100" type="text" v-model="editContentModel.endDate"/>
          </div>

          <div class="col-md-12 form-group">
            <label class="d-block">Photo Url:</label>
            <InputText class="w-100" type="text" v-model="editContentModel.photoUrl"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect class="w-100" v-model="editContentModel.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id" placeholder="Select Tags"/>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  name: "Cosmic Events",
  components: {Header, InputText, TranslationsModal},
  data() {
    return {
      selectedContentType: 'cosmic-event',
      contentTypes: [
        {id: "cosmic-event", name: "Cosmic Event"},
      ],
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        house: {value: null},
        gender: {value: null},
        relationshipStatusIds: {value: null},
        score: {value: []},
        occupation: {value: null},
        minAge: {value: null},
        maxAge: {value: null},
      },
      contentsLazyParams: {},
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        storyPrefix: "",
        houseId: null,
        ascendantSignId: null,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatus: 0,
        autoTranslate: false,
        autoAudioLanguages: [],
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
    this.newContent.categoryIds = this.categories.map(category => category.id);
    this.newContent.occupationIds = this.occupations.map(occupation => occupation.id);
    this.newContent.relationshipStatusIds = this.relationships.map(relationship => relationship.id);
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
  },
  watch: {},
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }
      this.$appAxios
          .post("admin/content/list/" + this.selectedContentType, {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showEditLanguage(id) {
      this.editTranslationContentId = id;
      this.showEditLanguageModal = true;
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/content/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified,
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            console.log(this.editContentModel)
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      if (!(this.editContentModel.isGeneral || this.editContentModel.ascendantSignId)) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Sign is required', life: 3000});
        return;
      }

      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.isGeneral ? null : this.editContentModel.ascendantSignId,
            houseId: this.editContentModel.houseId,
            gender: this.editContentModel.gender,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            type: this.selectedContentType,
            isGeneral: this.editContentModel.isGeneral,
            startDate: this.editContentModel.startDate,
            endDate: this.editContentModel.endDate,
            photoUrl: this.editContentModel.photoUrl,
            categoryIds: this.editContentModel.categoryIds,
            isPremium: this.editContentModel.isPremium,
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    updateAutoAudio() {
      if (!this.newContent.autoTranslate) {
        this.newContent.autoAudio = false;
      }
    },
    addContent() {
      if (!(this.newContent.isGeneral || this.newContent.ascendantSignId)) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Sign is required', life: 3000});
        return;
      }

      this.$appAxios.post("admin/content/v2/cosmic-event", {
        type: this.selectedContentType,
        startDate: this.newContent.startDate,
        endDate: this.newContent.endDate,
        photoUrl: this.newContent.photoUrl,
        title: this.newContent.title,
        text: this.newContent.text,
        categoryIds: this.newContent.categoryIds,
        ascendantSignId: this.newContent.isGeneral ? null : this.newContent.ascendantSignId,
        isPremium: this.newContent.isPremium,
        isGeneral: this.newContent.isGeneral,
        storyPrefix: this.newContent.storyPrefix,
        autoTranslate: this.newContent.autoTranslate,
        autoAudio: this.newContent.autoAudio,


        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatusIds: this.newContent.relationshipStatusIds,
        occupationIds: this.newContent.occupationIds,
      })
          .then(() => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added', life: 3000});
            this.getContents();
            this.showNewContentModal = false;
          })
          .catch(err => {
            console.log(err)
          });
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  