<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/in-app-message/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'total_records'"
                        @detail-see="detailSee" @detail-see2="detailSee2" @delet-see="deleteSee" @add-button="addInAppMessage" @getLookups-button="getLookups" :getLookupsButton="'New language campaign'"  :addButton="true" :isOnlyNodata="false" :dataPagination="true"/>



          <CreateModal
              :show="showCreateModal"
              modalTitle="Create In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'Type', type: 'text', model: 'type', placeholder: 'Enter type', required: false },
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], id: 'id', value: 'value', required: true},
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: false },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: false },
                { label: 'Show Count', type: 'switch', model: 'showCount', required: false },
                { label: 'Limit', type: 'number', model: 'score', placeholder: 'Enter Limit', required: true },
                { label: 'Dialog Type', type: 'select', model: 'dialogType', options: dialogTypes, id: 'id', value: 'name', required: true},
                { label: 'Is Once Show', type: 'switch', model: 'isOnceShow', required: true },
                { label: 'In App Message Type Id', type: 'number', model: 'inAppMessageTypeId', placeholder: 'Enter In App Message Type Id', required: true }
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />
          <CreateModal
              :show="showModalCrud"
              modalTitle="New language campaign"
              modalWidth="50%"
              :formFields="[
                { label: 'Type', type: 'text', model: 'type', placeholder: 'Enter type', required: false },
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], id: 'id', value: 'value', required: true},
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: true },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: true },
                { label: 'Show Count', type: 'switch', model: 'showCount', required: true },
                { label: 'Limit', type: 'number', model: 'score', placeholder: 'Enter Limit', required: true },
                { label: 'Language', type: 'drop', model: 'language', placeholder: 'Enter language', options: supportedLanguages , id: 'id', value: 'name' },
                { label: 'Title', type: 'text', model: 'title', required: true},
                { label: 'Text', type: 'text', model: 'text', required: true },
                { label: 'Is Verified', type: 'switch', model: 'isVerified', required: true },
                { label: 'Secondary Text', type: 'text', model: 'secondaryText', required: true },
                { label: 'Photo Url', type: 'text', model: 'photoUrl', placeholder: 'Enter photo url', required: true },
                { label: 'In AppMessage Type Id', type: 'number', model: 'inAppMessageTypeId', placeholder: 'Enter In App Message Type Id', required: true },
                { label: 'Dialog Type', type: 'select', model: 'dialogType', options: dialogTypes, id: 'id', value: 'name', required: true},
                { label: 'Is Once Show', type: 'switch', model: 'isOnceShow', required: true },
              ]"
              @saveEndpoint="createModalObj"
              method="post"
              @hide="showModalCrud = false"
              @success="refreshTable"
          />

          <EditModal
              :show="showEditModal"
              modalTitle="Edit In App Message"
              modalWidth="50%"
              :formFields="[
                { label: 'id', type: 'number', model: 'id', placeholder: 'Enter id', required: false },
                { label: 'Type', type: 'text', model: 'type', placeholder: 'Enter type', required: false },
                { label: 'Gender', type: 'select', model: 'gender', options: [
                    { text: 'All', value: 0 },
                    { text: 'Man', value: 1 },
                    { text: 'Woman', value: 2 }], value: 'value', text: 'text'
                },
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: true },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: true },
                { label: 'Show Count', type: 'switch', model: 'showCount', required: true },
                { label: 'Limit', type: 'number', model: 'score', placeholder: 'Enter Limit', required: true },
                { label: 'Dialog Type', type: 'select', model: 'dialogType', options: dialogTypes , value: 'id', text: 'name'
                },
                { label: 'Is Once Show', type: 'switch', model: 'isOnceShow', required: true },
                { label: 'inAppMessageTypeId', type: 'number', model: 'inAppMessageTypeId', placeholder: 'Enter In App Message Type Id', required: true }
              ]"
              :detailEndpoint="`/admin/in-app-message/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />


          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete In App Message"
              deleteMessage="Are you sure you want to delete this in app message?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/in-app-message/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";

export default {
  components: {AstopiaTable},
  data(){
    return {
      columns: [
        {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "type", header: "type", functionType: "none", edit: true, delete: true, selected: true},
        {field: "gender", header: "gender", functionType: "none", selected: true},
        {field: "isGeneral", header: "Is General", functionType: "bool", selected: true},
        {field: "isPremium", header: "Is Premium", functionType: "bool", selected: true},
        {field: "showCount", header: "Show Count", functionType: "bool", selected: true},
        {field: "score", header: "Limit", functionType: "none", selected: true},
        {field: "dialogType", header: "Dialog Type", functionType: "none", selected: true},
        {field: "isOnceShow", header: "Is Once Show", functionType: "bool", selected: true},
        {field: "inAppMessageTypeId", header: "In App Message Type Id", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "id": 0,
        "type": "",
        "gender": 0,
        "isGeneral": true,
        "isPremium": true,
        "showCount": true,
        "score": 0,
        "dialogType": 0,
        "isOnceShow": true,
        "inAppMessageTypeId": 0
      },
      dataUpdate:{
        "id": 0,
        "type": "",
        "gender": 0,
        "isGeneral": true,
        "isPremium": true,
        "showCount": true,
        "score": 0,
        "dialogType": 0,
        "isOnceShow": true,
        "inAppMessageTypeId": 0
      },
      dataModal:{
        "id": 0,
        "type": "string",
        "gender": 0,
        "isGeneral": true,
        "isPremium": true,
        "showCount": true,
        "score": 0,
        "language": "string",
        "title": "string",
        "text": "string",
        "isVerified": true,
        "secondaryText": "string",
        "photoUrl": "string",
        "inAppMessageTypeId": 0,
        "dialogType": 0,
        "isOnceShow": true,
      },
      showModalCrud: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      dialogTypes: null
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/in-app-message/dialog-types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    createObj(obj) {
      this.data.type = obj.type;
      this.data.gender = obj.gender;
      this.data.isGeneral = obj.isGeneral;
      this.data.isPremium = obj?.isPremium;
      this.data.showCount = obj?.showCount;
      this.data.score = obj?.score;
      this.data.dialogType = obj?.dialogType;
      this.data.isOnceShow = obj?.isOnceShow;
      this.data.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.$appAxios.post("/admin/in-app-message/create", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    createModalObj(obj) {
      this.dataModal.type = obj.type;
      this.dataModal.gender = obj.gender;
      this.dataModal.isGeneral = obj.isGeneral;
      this.dataModal.isPremium = obj?.isPremium;
      this.dataModal.showCount = obj?.showCount;
      this.dataModal.score = obj?.score;
      this.dataModal.language = obj?.language;
      this.dataModal.title = obj.title;
      this.dataModal.text = obj.text;
      this.dataModal.isVerified = obj.isVerified;
      this.dataModal.secondaryText = obj?.secondaryText;
      this.dataModal.photoUrl = obj?.photoUrl;
      this.dataModal.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.dataModal.dialogType = obj?.dialogType;
      this.dataModal.isOnceShow = obj?.isOnceShow;
      this.$appAxios.post("/admin/in-app-message/new-language-campaign", this.dataModal).then(() => {
        this.refreshTable()
        this.showModalCrud = false
      }).catch(() => {
        this.showModalCrud = false
      })
    },
    detailSee(obj){
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    detailSee2(obj){
      this.$router.push({name:"Translations", params:{id:obj.id}})
    },
    editObj(obj) {
      this.dataUpdate.id = obj.id;
      this.dataUpdate.type = obj.type;
      this.dataUpdate.gender = obj.gender;
      this.dataUpdate.isGeneral = obj.isGeneral;
      this.dataUpdate.isPremium = obj?.isPremium;
      this.dataUpdate.showCount = obj?.showCount;
      this.dataUpdate.score = obj?.score;
      const numericValue = Number(obj?.dialogType);
      if (numericValue){
        this.dataUpdate.dialogType = numericValue;
      }
      this.dataUpdate.isOnceShow = obj?.isOnceShow;
      this.dataUpdate.inAppMessageTypeId = obj?.inAppMessageTypeId;
      this.$appAxios.put("/admin/in-app-message", this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    getLookups(){
      this.showModalCrud = true
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    }
  }
}
</script>

