<template>
  <Header/>
  <main class="h-100">
    <div class="container h-100">
      <div class="row h-100">
        <div
            class="col-12 m-0 no-max-w home-div d-flex align-items-center justify-content-center"
        >
          <h2 class="fw-bold">{{$t('welcome.SelectFromMenu')}}</h2>
        </div>
      </div>
    </div>
  </main>
  <vue-scroll-up custom-class="my-scroll-up" :scroll-duration="20">
    <svg
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M16.778 9.24268C17.1685 8.85215 17.1685 8.21899 16.778 7.82846L9.70693 0.757398C9.31641 0.366874 8.68324 0.366875 8.29272 0.757399L1.22165 7.82846C0.831127 8.21898 0.831127 8.85215 1.22165 9.24268C1.61218 9.6332 2.24534 9.6332 2.63586 9.24268L8.29272 3.58582C8.68324 3.1953 9.31641 3.1953 9.70693 3.58582L15.3638 9.24268C15.7543 9.6332 16.3875 9.6332 16.778 9.24268Z"
          fill="#FFFFFF"
      />
    </svg>
  </vue-scroll-up>
</template>

<script>
import Header from "@/components/Header.vue";
import VueScrollUp from "vue-scroll-up";

export default {
  name: "Home",
  components: {VueScrollUp, Header},
  data() {
    return {
      sessions: [],
    };
  },
  mounted() {
    if (!this.$store.state.token) {
      this.$store.commit("logout")
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.hidden {
  display: none;
}

.display {
  display: block !important;
}

.loading-container {
  background-color: #eff0f1;
}

.upcoming-class-container {
  width: 854px;
  height: 261px;
  border-radius: 20px;
  margin-top: 34px;
  overflow: hidden;
}

upcoming-class-container-parent {
  width: 654px;
  height: 261px;
  border-radius: 20px;
  margin-top: 34px;
  overflow: hidden;
}

h1.profile-header {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3a3d4b;
  opacity: 1;
  margin-left: 18px;
  margin-top: 20px;
}

.slider-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: calc(100% - 88px);
  margin-left: 88px;
}

.glide_tutors {
  padding-top: 100px;
}

.slider-header {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3a3d4b;
  opacity: 1;
}
</style>
