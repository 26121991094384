<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/rag/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="''" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addPublicFigure" :addButton="true" :dataPagination="false" :isOnlydata="true" :optionsList="optionsList" :selectedObj1="true" :selectedObj2="true"/>

          <EditModal
              :show="showEditModal"
              modalTitle="Edit Rag"
              modalWidth="50%"
              :formFields="[
                { label: 'Language', type: 'select', model: 'language', options: [{'value': 'en', 'text': 'English' }, { 'value': 'tr', 'text': 'Türkçe' }], value: 'value', text: 'text', required: true},
                { label: 'Report Name', type: 'text', model: 'reportName', placeholder: 'Enter name', required: true },
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter surname', required: true },
                { label: 'Description', type: 'text', model: 'description', placeholder: 'Enter photo URL', required: true },
                { label: 'Sub Description', type: 'text', model: 'subDescription', placeholder: 'Enter sub description', required: true },
                { label: 'Slug', type: 'text', model: 'slug', placeholder: 'Enter slug', required: true },
                { label: 'Report Image Path', type: 'text', model: 'reportImagePath', required: true},
                { label: 'Example Url', type: 'text', model: 'exampleUrl', required: true},
                { label: 'Report Request Url', type: 'text', model: 'reportRequestUrl', required: true },
                { label: 'Stripe Product Id', type: 'text', model: 'stripeProductId', required: true },
                { label: 'Stripe Price Id', type: 'text', model: 'stripePriceId', required: true},
                { label: 'Button Text', type: 'text', model: 'buttonText', required: true},
                { label: 'Adapty Product Id', type: 'text', model: 'adaptyProductId', required: true},
                { label: 'Is New', type: 'switch', model: 'isNew', required: true},
                { label: 'Is isWeb', type: 'switch', model: 'isWeb', required: true},
                { label: 'Is isApp', type: 'switch', model: 'isApp', required: true},
                { label: 'Is Rag', type: 'switch', model: 'isRag', required: true},
                { label: 'Is Single User', type: 'switch', model: 'isSingleUser', required: true},
                { label: 'Properties', type: 'properties', model: 'properties', required: true}
              ]"
              :detailEndpoint="`/admin/rag/get/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="'data'"
              :isOnlydata="true"
              @hide="showEditModal = false"
          />


          <CreateModal
              :show="showCreateModal"
              modalTitle="Create Rag"
              modalWidth="50%"
              :formFields="[
                { label: 'Language', type: 'select', model: 'language', options: [{'id': 'en', 'name': 'English' }, { 'id': 'tr', 'name': 'Türkçe' }], id: 'id', value: 'name', required: true},
                { label: 'Report Name', type: 'text', model: 'reportName', placeholder: 'Enter report name', required: true },
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter title', required: true },
                { label: 'Description', type: 'text', model: 'description', placeholder: 'Enter description', required: true },
                { label: 'Sub Description', type: 'text', model: 'subDescription', placeholder: 'Enter sub description', required: true },
                { label: 'Slug', type: 'text', model: 'slug', placeholder: 'Enter slug', required: true, popover: true, popoverText: 'Web tarafı için zorunlu. Pdf örneği linki' },
                { label: 'Report Image Path', type: 'text', model: 'reportImagePath', required: true},
                { label: 'Report Request Url', type: 'text', model: 'reportRequestUrl', required: true },
                { label: 'Example Url', type: 'text', model: 'exampleUrl', required: true, popover: true, popoverText: 'Web tarafı için zorunlu. Pdf örneği linki' },
                { label: 'Stripe Product Id', type: 'text', model: 'stripeProductId', required: true, popover: true, popoverText: 'Stripe\'dan alınan ID' },
                { label: 'Stripe Price Id', type: 'text', model: 'stripePriceId', required: true},
                { label: 'Button Text', type: 'text', model: 'buttonText', required: true},
                { label: 'Adapty Product Id', type: 'text', model: 'adaptyProductId', required: true},
                { label: 'Is New', type: 'switch', model: 'isNew', required: true},
                { label: 'Is Web', type: 'switch', model: 'isWeb', required: true},
                { label: 'Is App', type: 'switch', model: 'isApp', required: true},
                { label: 'Is Rag', type: 'switch', model: 'isRag', required: true},
                { label: 'Is Single User', type: 'switch', model: 'isSingleUser', required: true},
                { label: 'Properties', type: 'properties', model: 'properties', required: true}
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />



          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete Rag"
              deleteMessage="Are you sure you want to delete this rag?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/rag/delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import AstopiaTable from "@/components/Tables/AstopiaTable.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";
import dist from "vue-sweetalert2/dist";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    Toast,
    AstopiaTable
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      selectedObjId: null,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      languages: [],
      countries: [],
      cities: [],
      states: [],
      optionsList: [{id: "false", name: "App"},{id: "true", name: "Web"}],
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "reportName", header: "Report Name", functionType: "none", selected: true},
        {field: "title", header: "Title", functionType: "none", selected: true},
        {field: "description", header: "Description", functionType: "none", selected: true},
        {field: "subDescription", header: "Sub Description", functionType: "none", selected: true},
        {field: "slug", header: "Slug", functionType: "none", selected: true},
        {field: "reportImagePath", header: "Report Image Path", functionType: "none", edit: true, delete: true, selected: true},
        {field: "reportRequestUrl", header: "Report Request Url", functionType: "none", selected: true},
        {field: "exampleUrl", header: "Example Url", functionType: "none", selected: true},
        {field: "stripeProductId", header: "Stripe Product Id", functionType: "none", selected: true},
        {field: "stripePriceId", header: "Stripe Price Id", functionType: "none", selected: true},
        {field: "amount", header: "Amount", functionType: "none", selected: true},
        {field: "currency", header: "Currency", functionType: "none", selected: true},
        {field: "buttonText", header: "Button Text", functionType: "none", selected: true},
        {field: "isNew", header: "Is New", functionType: "bool", selected: true},
        {field: "isWeb", header: "Is Web", functionType: "bool", selected: true},
        {field: "isApp", header: "Is App", functionType: "bool", selected: true},
        {field: "isRag", header: "Is Rag", functionType: "bool", selected: true},
        {field: "isSingleUser", header: "Is Single User", functionType: "bool", selected: true},
        {field: "adaptyProductId", header: "Adapty Product Id", functionType: "none", selected: true},
        {field: "properties", header: "Properties", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "reportName": "string",
        "title": "string",
        "description": "string",
        "subDescription": "string",
        "slug": "string",
        "language": "string",
        "reportImagePath": "string",
        "reportRequestUrl": "string",
        "exampleUrl": "string",
        "stripeProductId": "string",
        "stripePriceId": "string",
        "buttonText": "string",
        "adaptyProductId": "string",
        "isNew": true,
        "isWeb": true,
        "isApp": true,
        "isRag": true,
        "isSingleUser": true,
        "properties": []
      },
      dataUpdate:{
        "reportName": "string",
        "title": "string",
        "description": "string",
        "subDescription": "string",
        "slug": "string",
        "language": "string",
        "reportImagePath": "string",
        "reportRequestUrl": "string",
        "exampleUrl": "string",
        "stripeProductId": "string",
        "stripePriceId": "string",
        "buttonText": "string",
        "adaptyProductId": "string",
        "isNew": true,
        "isWeb": true,
        "isApp": true,
        "isRag": true,
        "isSingleUser": true,
        "properties": []
      }
    };
  },
  mounted() {
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    dist() {
      return dist
    },
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    openEditModal(rowData) {
      this.editUserModel = rowData;
      this.showEditBirthdateModal = true;
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id

      this.showDeleteModal = true
    },
    detailSee(obj) {
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate.reportName = obj.reportName;
      this.dataUpdate.title = obj.title;
      this.dataUpdate.description = obj.description;
      this.dataUpdate.subDescription = obj.subDescription;
      this.dataUpdate.slug = obj.slug;
      this.dataUpdate.language = obj.language;
      this.dataUpdate.reportImagePath = obj?.reportImagePath;
      this.dataUpdate.reportRequestUrl = obj?.reportRequestUrl;
      this.dataUpdate.exampleUrl = obj?.exampleUrl;
      this.dataUpdate.stripeProductId = obj?.stripeProductId;
      this.dataUpdate.stripePriceId = obj?.stripePriceId;
      this.dataUpdate.buttonText = obj?.buttonText;
      this.dataUpdate.adaptyProductId = obj?.adaptyProductId;
      this.dataUpdate.isNew = obj?.isNew;
      this.dataUpdate.isWeb = obj?.isWeb;
      this.dataUpdate.isApp = obj?.isApp;
      this.dataUpdate.isRag = obj.isRag;
      this.dataUpdate.isSingleUser = obj.isSingleUser;
      this.dataUpdate.properties = obj?.properties;



      const headers = {
        "Accept-Language": obj?.language,
      };

      this.$appAxios.put(`/admin/rag/edit/${this.selectedObjId}`, this.dataUpdate, { headers }).then(() => {

        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    createObj(obj) {
      this.data.reportName = obj.reportName;
      this.data.title = obj.title;
      this.data.description = obj.description;
      this.data.subDescription = obj.subDescription;
      this.data.slug = obj.slug;
      this.data.language = obj.language;
      this.data.isRag = obj.isRag;
      this.data.isSingleUser = obj.isSingleUser;
      this.data.reportImagePath = obj?.reportImagePath;
      this.data.reportRequestUrl = obj?.reportRequestUrl;
      this.data.exampleUrl = obj?.exampleUrl;
      this.data.stripeProductId = obj?.stripeProductId;
      this.data.stripePriceId = obj?.stripePriceId;
      this.data.buttonText = obj?.buttonText;
      this.data.adaptyProductId = obj?.adaptyProductId;
      this.data.isNew = obj?.isNew;
      this.data.isWeb = obj?.isWeb;
      this.data.isApp = obj?.isApp;
      this.data.properties = obj?.properties;

      const headers = {
        "Accept-Language": obj?.language,
      };

      this.$appAxios.post("/admin/rag/create", this.data, {headers}).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    addPublicFigure() {
      this.showCreateModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
      this.$refs.astopiaTable.getObj();
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>