<template>
  <div>
    <div
        style="width: 1.3rem;height: 1.3rem;padding: 0.2rem 0.2rem;margin-bottom: 0.5rem !important;border-radius: 1.875rem;"
        class="info btn btn-icon-only btn-rounded btn-outline-secondary mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
        @mouseover="openPopover"
        @mouseleave="closePopover"
    >
      <i class="fas fa-info" aria-hidden="true"></i>
    </div>

    <div v-if="isPopoverVisible && text" ref="popoverContent" class="info-Popover">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPopoverVisible: false,
    };
  },
  created() {

  },
  methods: {
    openPopover() {
      this.isPopoverVisible = true;
    },
    closePopover() {
      this.isPopoverVisible = false;
    },
  },
};
</script>

<style>
.info-Popover {
  position: absolute;
  background: white;
  border-radius: 1em;
  width: 30vh;
  padding: 10px;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(50, 50, 93, 0.1), 0 1px 11px rgba(0, 0, 0, 0.08);
  display: none; /* İlk başta gizli olacak */
}

/* :hover ile işaretleme */
.info:hover + .info-Popover {
  display: block;
}

/* veya şu şekilde de yapılabilir */
/*
.info-Popover:hover {
  display: block;
}
*/
</style>
