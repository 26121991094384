<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>

          <DataTable
              :value="contents"
              :lazy="true"
              :paginator="true"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 20, 50, 100]"
              dataKey="id"
              :rows="10"
              ref="dt"
              :totalRecords="contentsTotalRecords"
              :loading="loading"
              @page="onContentsPage($event)"
              @sort="onContentsSort($event)"
              @filter="onContentsFilter($event)"
              responsiveLayout="scroll"
              class="p-datatable-striped"
              v-model:filters="filters"
              filterDisplay="menu"
              removableSort
              :globalFilterFields="['name', 'isActive']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <template #header>
              <div  class="d-flex justify-content-center">
                <div class="flex justify-content-center">
                  <h3>Sorular</h3>
                </div>
                <div
                    class="pe-md-3 d-flex align-items-center"
                    :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
                >
                  <div class="input-group">
                        <span style="border: 1px solid #6366F1;" class="input-group-text text-body">
                          <i class="fas fa-search" aria-hidden="true"></i>
                        </span>
                    <input style="border: 1px solid rgb(99, 102, 241) !important; !important;border-left: transparent !important;" type="text" class="form-control" :placeholder="this.$store.state.isRTL ? 'أكتب هنا...' : 'Type here...'"
                           v-model="searchQuery"
                           @input="getContents"
                    />
                  </div>
                </div>
              </div>
            </template>

            <template #empty> No content found.</template>
            <template #loading> Loading contents. Please wait.</template>
            <Column field="name" header="İsim">
              <template #body="slotProps">
                <Button
                    :label="slotProps.data.name"
                    icon="pi pi-user"
                    v-tooltip="'Kullanıcı Detayları'"
                    class="p-button-text p-button-rounded"
                    @click="openUserDetailModal(slotProps.data.userId)"
                />
              </template>
            </Column>
            <Column field="type" header="Tip"/>
            <Column field="subject" header="Konu"/>
            <Column field="question" header="Soru">
              <template #body="slotProps">
                <ScrollPanel style="width: 250px; height: 200px" class="custombar">
                  <span class="m-0">{{ slotProps.data.question }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="answer" header="Cevap">
              <template #body="slotProps">
                <div class="hover-container" v-if="slotProps.data.answer">
                  <Button
                      icon="pi pi-trash"
                      class="delete-button p-button-danger p-button-sm"
                      v-tooltip="'Cevabı Sil'"
                      @click="deleteAnswer(slotProps.data.id)"
                  />
                  <ScrollPanel style="width: 250px; height: 200px" class="custombar">
                    <span class="m-0">{{ slotProps.data.answer }}</span>
                  </ScrollPanel>
                </div>
              </template>
            </Column>
            <Column field="astrologName" header="Astrolog"/>
            <Column field="" header="Detay" style="width: 200px;">
              <template #body="slotProps">
                <div v-for="v in slotProps.data.detail" :key="v">
                  <div v-if="v['text']">
                    <b>{{ v['title'] }}</b>
                    <br/>
                    {{ v['text'] }}
                    <br/>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="createdDate" header="Sorulma Tarihi"/>
            <Column header="Kalan Süre">
              <template #body="slotProps">
                <span class="m-0">{{ getRemainingTime(slotProps.data.createdDate) }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
          <Dialog header="Cevapla" v-model:visible="showAnswerQuestionModal" modal :style="{ width: '35vw' }">
            <div class="row">
              <div class="col-12">
                        <Textarea
                            v-model="newAnswerModel.text"
                            rows="10"
                            cols="50"
                            placeholder="Cevabınızı buraya yazınız."
                        />
              </div>
            </div>
            <template #footer>
              <Button
                  @click="showAnswerQuestionModal = false"
                  label="İptal"
                  class="p-button-text"
              />
              <Button
                  @click="answerQuestion"
                  label="Cevapla"
              />
            </template>
          </Dialog>
          <Dialog header="Kullanıcı Detayları" v-model:visible="showUserDetailModal" modal :style="{ width: '50vw' }">
            <div class="row">
              <div class="col-6">
                <label class="d-block">İsim</label>
                <InputText v-model="userDetail.name" disabled/>
              </div>
              <div class="col-6">
                <label class="d-block">Doğum Tarihi</label>
                <InputText v-model="userDetail.birthDate" disabled/>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Doğum Saati</label>
                {{ userDetail.birthHour + ":" + userDetail.birthMinute }}
              </div>
              <div class="col-6">
                <label class="d-block">Doğum Yeri</label>
                {{ userDetail.birthCountry + "/" + userDetail.birthCity }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Meslek</label>
                {{ userDetail.job }}
              </div>
              <div class="col-6">
                <label class="d-block">Medeni Hali</label>
                {{ userDetail.relationship }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Cinsiyet</label>
                {{ userDetail.genderText }}
              </div>
              <div class="col-6">
                <label class="d-block">Burç</label>
                {{ userDetail.sign }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Yükselen</label>
                {{ userDetail.ascendingSign }}
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img :src="userDetail.birthChartUrl" alt="">
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat)
dayjs.extend(duration)

export default {
  name: "Workshops",
  components: {
    Header,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      schema,
      messages: [],
      messageCount: 0,
      showAnswerQuestionModal: false,
      newAnswerModel: {},
      showUserDetailModal: false,
      userDetail: {},
      searchQuery: '',
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.getContents();
  },
  computed: {},
  methods: {
    getContents() {
      this.loading = true;


      this.$appAxios
          .post("admin/question/list", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify({ global: this.searchQuery }),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    openAnswerQuestionModal(id) {
      this.newAnswerModel.questionId = id;
      this.showAnswerQuestionModal = true;
    },
    openUserDetailModal(userId) {
      this.$appAxios
          .get("admin/question/user-detail/" + userId)
          .then((resp) => {
            this.userDetail = resp.data;
            this.showUserDetailModal = true;
          })
          .catch(() => {
          });
    },
    getRemainingTime(date) {
      var now = dayjs();
      var end = dayjs(date, 'DD.MM.YYYY HH:mm').add(3, 'day');
      var diff = end.diff(now);
      if (diff < 0) return 0;

      return dayjs.duration(diff).format("D [Gün], H [Saat], m [Dakika]");
    },
    answerQuestion() {
      this.$appAxios
          .post("admin/question/answer", this.newAnswerModel)
          .then(() => {
            this.showAnswerQuestionModal = false;
            this.getContents();
          })
          .catch(() => {
          });
    },
    filteredDetails(details) {
      return Object.fromEntries(
          Object.entries(details).filter(([, value]) => !!value)
      );
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete this content?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/question/" + id)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Content deleted successfully',
                  life: 3000
                });
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occured while deleting content',
                  life: 3000
                });
              });
        },
        reject: () => {
          this.$toast.add({severity: 'info', summary: 'Info', detail: 'Content not deleted', life: 3000});
        }
      });
    },
    deleteAnswer(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete this answer?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/question/answer/" + id)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Answer deleted successfully',
                  life: 3000
                });
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occured while deleting answer',
                  life: 3000
                });
              });
        },
        reject: () => {
          this.$toast.add({severity: 'info', summary: 'Info', detail: 'Answer not deleted', life: 3000});
        }
      });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}

.hover-container {
  position: relative;
  display: flex;
  justify-content: between;
  align-items: center;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  z-index: 10;
}

.hover-container:hover .delete-button {
  visibility: visible;
}
</style>
  